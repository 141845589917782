import { format } from 'date-fns';

import { isValidDate } from './is-valid-date';

// Helper function to convert date to a specific time zone
const convertToTimeZone = (date: Date, timeZone: string): Date => {
    const options = { timeZone, year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    const formatter = new Intl.DateTimeFormat([], options);
    const parts = formatter.formatToParts(date);
    const year = parts.find(part => part.type === 'year')?.value;
    const month = parts.find(part => part.type === 'month')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    return new Date(`${year}-${month}-${day}`);
};

// Format date to server readable format
export const formatDate = (date: Date | string): string => {
    if (typeof date !== 'string' && isValidDate(date)) {
        const localDate = convertToTimeZone(date, 'US/Arizona');
        return format(localDate, 'yyyy-MM-dd');
    } else {
        return '';
    }
};